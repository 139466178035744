import '../../css/misc/rocket.css'

function Rocket(props) {
  return (
    <div className="rocket" style={{rotate: props.rotate}}>
        {/* <svg version="1.1" viewBox="0 0 1024 1024" width="100%" xmlns="http://www.w3.org/1999/xlink">
            <path className="rocket-flame" fill="#4474ab" opacity="1" stroke="none" d="M395.333 724.917C395.333 692.7 408.392 663.533 429.504 642.421C450.617 621.308 479.783 608.25 512 608.25C544.217 608.25 573.383 621.308 594.496 642.421C615.608 663.533 628.667 692.7 628.667 724.917C628.667 757.133 615.608 786.3 594.496 807.412C573.383 828.525 544.217 958.25 512 958.25C479.783 958.25 450.617 828.525 429.504 807.412C408.392 786.3 395.333 757.133 395.333 724.917Z"/>
            <path className="rocket-window" fill="#4474ab" opacity="1" stroke="none" d="M395.333 310.75C395.333 246.317 447.567 194.083 512 194.083C576.433 194.083 628.667 246.317 628.667 310.75C628.667 375.183 576.433 427.417 512 427.417C447.567 427.417 395.333 375.183 395.333 310.75Z"/>
            <path className="rocket-fuselage" fill="none" opacity="1" stroke="#4474ab" strokeWidth="70" d="M162 649.083C162 649.083 278.667 65.75 512 65.75C745.333 65.75 862 649.083 862 649.083"/>
        </svg> */}
        {/* <svg height="100%" viewBox="0 0 1024 1024" width="100%" xmlns="http://www.w3.org/2000/svg">
          <path 
            className="rocket-fuselage" strokeWidth="70" fill="none" style={{stroke: props.color ?? ""}}
            d="M280.284 800C280.284 800 167.673 694.375 181.678 640C195.684 585.625 217.173 502.5 245.826 430C274.479 357.5 310.616 285 354.557 230.625C398.498 176.25 450.244 140 510.116 140C569.987 140 621.733 176.25 665.674 230.625C709.616 285 745.752 357.5 774.405 430C803.058 502.5 824.547 585.625 838.553 640C852.559 694.375 760.284 800 760.284 800" 
          />
          
          <path 
            className="rocket-window" style={{fill: props.color ?? ""}}
            d="M418.395 720L622.173 720C628.425 720 635.096 721.321 637.074 722.951C637.074 722.951 786.536 860 780.284 860L723.92 860L698.466 900L571.193 900L545.738 860L523.007 860L494.829 860L469.375 900L342.102 900L316.648 860C316.648 860 258.307 861.63 260.284 860L403.494 722.951C405.471 721.321 412.143 720 418.395 720Z"
          />
          
          <path 
            className="rocket-engine" style={{fill: props.color ?? ""}}
            d="M360.284 496.364C360.284 472.727 364.458 452.219 372.005 426.389C379.553 400.558 390.475 373.461 403.971 348.81C417.467 324.16 433.537 301.958 451.382 285.918C469.227 269.877 488.846 260 509.441 260C530.035 260 549.654 269.877 567.499 285.918C585.344 301.958 601.414 324.16 614.91 348.81C628.406 373.461 639.328 400.558 646.876 426.389C654.424 452.219 658.597 476.783 658.597 496.364C658.597 515.945 608.879 520 608.879 520L509.441 520L410.003 520C410.003 520 360.284 520 360.284 496.364Z"
          />
        </svg> */}

        <svg height="100%" viewBox="0 0 1024 1024" width="100%" xmlns="http://www.w3.org/2000/svg">
          <path 
            className="rocket-fuselage" style={{fill: props.color ?? ""}}
            d="M685.236 219.063C726.347 269.754 762.599 337.528 794.668 418.384C807.632 451.067 819.77 487.499 831.696 528.171C840.286 557.471 846.675 581.316 857.48 623.112C863.969 648.216 857.568 675.967 842.399 707.357C835.332 721.981 826.396 737.123 816.045 752.586C807.132 765.902 797.657 778.758 788.162 790.706C784.833 794.896 781.73 798.691 778.928 802.028C777.931 803.216 775.403 806.166 775.18 806.42L724.602 762.385C724.677 762.299 726.587 760.061 727.42 759.069C729.846 756.179 732.571 752.855 735.515 749.151C743.945 738.543 752.373 727.131 760.19 715.453C768.927 702.4 776.289 689.89 781.866 678.349C790.768 659.929 794.023 645.867 792.45 639.781C781.815 598.642 775.568 575.383 767.235 546.962C755.845 508.114 744.302 473.545 732.187 443C702.405 367.912 669.156 305.734 632.978 261.126C593.101 211.957 552.068 186.918 509.874 186.918C467.68 186.918 426.617 211.957 386.74 261.126C350.562 305.734 317.313 367.912 287.531 443C275.416 473.545 263.873 508.114 252.483 546.962C244.15 575.383 237.903 598.642 227.269 639.781C227.246 639.868 227.23 640.071 227.209 640.379C227.172 640.897 227.173 641.532 227.239 642.321C227.412 644.418 227.97 647.071 228.947 650.178C231.172 657.25 235.305 665.842 241.18 675.481C248.04 686.736 256.961 698.974 267.444 711.719C277.276 723.673 287.889 735.367 298.505 746.283C302.217 750.101 305.663 753.542 308.728 756.53C309.784 757.558 312.219 759.895 312.326 759.995L266.394 808.81C266.127 808.56 263.013 805.563 261.777 804.358C258.297 800.966 254.437 797.117 250.294 792.857C238.466 780.694 226.631 767.654 215.516 754.14C203.176 739.137 192.462 724.443 183.795 710.225C175.312 696.306 168.876 683.005 164.847 670.193C159.675 653.748 158.381 638.035 162.239 623.112C173.043 581.316 179.432 557.471 188.023 528.171C199.948 487.499 212.117 451.067 225.08 418.384C257.15 337.528 293.401 269.754 334.512 219.063C385.977 155.606 444.366 120 509.874 120C575.382 120 633.771 155.606 685.236 219.063Z"
          />
          
          <path
            className="rocket-window" style={{fill: props.color ?? ""}}
            d="M366.115 494.134C366.115 471.538 370.12 451.933 377.361 427.24C384.602 402.546 395.081 376.641 408.029 353.077C420.977 329.512 436.395 308.286 453.516 292.953C470.636 277.619 489.459 268.176 509.218 268.176C528.976 268.176 547.799 277.619 564.919 292.953C582.04 308.286 597.458 329.512 610.406 353.077C623.354 376.641 633.833 402.546 641.074 427.24C648.315 451.933 652.32 475.415 652.32 494.134C652.32 512.853 604.619 516.73 604.619 516.73L509.218 516.73L413.816 516.73C413.816 516.73 366.115 516.73 366.115 494.134Z"
          />
          
          <path 
            className="rocket-engine" style={{fill: props.color ?? ""}}
            d="M392.728 760L645.969 760C650.22 760 655.871 763.219 658.593 767.19L744.679 892.81C747.4 896.781 746.16 900 741.91 900L296.787 900C292.536 900 291.296 896.781 294.018 892.81L380.104 767.19C382.825 763.219 388.477 760 392.728 760Z"
          />
        </svg>

    </div>
  );
}

export default Rocket;
